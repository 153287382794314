import type { Stripe } from 'stripe';
import type { TemplateResult } from 'lit-element';
import type { Plan } from 'common/types';
import type { Button } from '@material/mwc-button';
import type { FirebaseError } from 'firebase';

import '../tbt-auth/tbt-auth';
import '@material/mwc-button';
import '@material/mwc-dialog';

import { customElement, html, property } from 'lit-element';
import { styleMap } from 'lit-html/directives/style-map';
import { StateElement, updateState, registerState } from '@pwrs/state';
import bind from 'bind-decorator';

import {
  cancelSubscription,
  accountStateInitializer,
  cancelDialogStateInitializer,
} from './account-state';

import shared from '../../shared.css';
import style from './tbt-account.css';
import { clock } from '../../lib/svg/clock.svg';

import { formatRelativeWithOptions } from 'date-fns/esm/fp';

registerState({ account: accountStateInitializer, cancelDialog: cancelDialogStateInitializer });

const format = formatRelativeWithOptions({ weekStartsOn: 0 }, new Date());

type TbtSubscription = Stripe.Subscription & { metadata: { planName: Plan } }

type SubscriptionCardElement = HTMLElement & {
  dataset: {
    subscriptionId: Stripe.Subscription['id'];
    planName: Plan;
  };
};

function isFirebaseError(error: unknown|object): error is FirebaseError & { details: any } {
  return error != null && Object.prototype.hasOwnProperty.call(error, 'details');
}

@customElement('tbt-account') export class TbtAccount extends StateElement {
  static readonly is = 'tbt-account'

  static readonly styles = [shared, style]

  @property({ type: Boolean, reflect: true }) active = false;

  protected render(): TemplateResult {
    const {
      auth: { user } = {},
      account: { error, inFlight, subscriptions = [] } = {},
      cancelDialog: {
        open = false,
        planName,
        ...cancelDialog
      } = cancelDialogStateInitializer.state,
    } = this.state;

    const hasSubscriptions =
      !!(subscriptions?.length);
      // !!(subscriptions && subscriptions.length);

    const showLoader =
      inFlight;

    const membershipHeaderText =
      error ? 'Something went wrong'
    : inFlight ? 'Loading your Memberships...'
    : !hasSubscriptions ? 'You have no active memberships.'
    : 'Your Memberships';

    const showNoSubscriptions = !inFlight && !hasSubscriptions;

    return html`
      <tbt-auth ?hidden="${user}" ?active="${this.active}"></tbt-auth>

      <header id="header" ?hidden="${!user}">
        <hgroup>
          <h2>Welcome, ${user && user.displayName}</h2>
          <h3>${membershipHeaderText}</h3>
        </hgroup>
        <mwc-button id="sign-out" @click="${this.signout}">Sign Out</mwc-button>
      </header>

      <tbt-card id="error" ?hidden="${!error}">
        <hgroup slot="header">
          <h2>Oh No!</h2>
          <h3>Something went wrong</h3>
        </hgroup>
        <div>
          <p>This is what we know:</p>
          <output>
            <pre>${error && error.message}</pre>
            ${(isFirebaseError(error) && typeof error.details === 'string') ? html`<pre>${error.details}</pre>` : ''}
          </output>
        </div>
        <menu slot="actions">
          <mwc-button label="Reload" @click="${(): void => window.location.reload()}"></mwc-button>
        </menu>
      </tbt-card>

      <section id="content" ?hidden="${!user || error}" style="${styleMap({ pointerEvents: showLoader ? 'none' : 'all' })}">
        ${showNoSubscriptions ? html`
          <p class="no-subscriptions">Join a Chabura today!</p>
          <tbt-pricing-table></tbt-pricing-table>
        ` : html`
          <menu id="subscriptions"> ${
              showLoader ? [0, 0, 0].map(this.subscriptionLoaderTemplate)
            : subscriptions.map(this.subscriptionTemplate)
          } </menu>
          <a href="/subscribe" tabindex="-1">Add A${subscriptions.length ? 'nother' : ''} Membership</a>
        `}
      </section>

      <footer>
        <p ?hidden="${!(error || user)}">If you have any questions or issues, please email our administrator <a href="mailto:chaburas@thebinahtree.com">chaburas@thebinahtree.com</a></p>
      </footer>

      <mwc-dialog
          heading="Are you Sure?"
          ?open="${open}"
          @closed="${this.onDialogClosed}"
          scrimClickAction="" escapeKeyAction="">

        <tbt-loader ?active="${inFlight || cancelDialog.inFlight}">${clock}</tbt-loader>

        <p>Are you sure you want to cancel your ${planName} membership?</p>

        <mwc-button
            slot="primaryAction"
            ?disabled="${cancelDialog.inFlight}"
            dialogaction="dismiss"
        >No, Stay Subscribed</mwc-button>

        <mwc-button
            unelevated
            slot="secondaryAction"
            ?disabled="${cancelDialog.inFlight}"
            @click="${this.cancelSubscription}"
        >Yes, Cancel</mwc-button>

      </mwc-dialog>
    `;
  }

  protected firstUpdated(): void {
    updateState({ account: { nextAction: 'init' } });
  }

  /** Signs the User out */
  public signout(): void {
    // const auth = await this.state.auth?.auth?.signout();
    const auth = this.state.auth && this.state.auth.auth;
    auth.signOut();
  }

  /** Resets the auth ui **/
  public reset(): void {
    updateState({ account: { error: null }, auth: { nextAction: 'reset' } });
  }

  private cancelSubscription(): void {
    cancelSubscription(this.state.cancelDialog);
  }

  private subscriptionLoaderTemplate(): TemplateResult {
    return html`
      <li class="fake subscription-loader" role="presentation">
        <tbt-card class="subscription">
          <h3 class="heading" slot="heading"></h3>
          <span class="description"></span>
          <button role="presentation" tabindex="-1" class="button" slot="actions"></button>
        </tbt-card>
      </li>
    `;
  }

  @bind private subscriptionTemplate(subscription: TbtSubscription): TemplateResult {
    const { created, id, metadata, plan, status } = subscription;
    const { planName } = metadata || {};
    const { nickname } = plan || {};
    const relativeDate = format(created * 1000);
    return status === 'canceled' ? html`` : html`
      <li>
        <tbt-card class="subscription" data-subscription-id="${id}" data-plan-name="${planName}">
          <h3 slot="heading">${planName || nickname}</h3>
          <span class="description">Joined <time datetime="${created}">${relativeDate}</time></span>
          <menu slot="actions">
            <mwc-button @click="${this.showConfirmDialog}">Cancel</mwc-button>
          </menu>
        </tbt-card>
      </li>
    `;
  }

  private onDialogClosed({ detail: reason }): void {
    if (reason !== 'dismiss') {
      updateState({
        cancelDialog: {
          error: null,
          open: false,
          planName: null,
          response: null,
          subscriptionId: null,
        },
      });
    }
  }

  private showConfirmDialog({ target }: MouseEvent & { target: Button }): void {
    const card = target.closest('.subscription') as SubscriptionCardElement;

    // const { subscriptionId } = card?.dataset as SubscriptionCardElement;
    const { subscriptionId, planName } = card.dataset;

    updateState({
      cancelDialog: {
        error: null,
        inFlight: false,
        open: true,
        planName,
        response: null,
        subscriptionId,
      },
    });
  }
}
