import type { Stripe } from 'stripe';
import type { CancelSubscriptionParams, Plan } from 'common/types';
import type { FirebaseError } from 'firebase';
import type { StateInitializer } from '@pwrs/state';

import { getState, receivedProp, updateState, when, not } from '@pwrs/state';

import { safeCallable } from '../../firebase/functions';

import { set, get } from 'idb-keyval';

type AccountActions =
    'init'
  | 'getSubscriptions'
  | null;

type GetSubscriptionsResult = {
  subscriptions: Stripe.Subscription[];
  error: Stripe.StripeError | Error;
}

interface CancelDialogState {
  open: boolean;
  /** True when request to cancel subscription is inflight */
  inFlight: boolean;
  /** Response from a cancel operation */
  response: Stripe.Subscription;
  /** Error when cancelling subscription */
  error?: Error | null;
  /** Plan to cancel */
  planName: Plan;
  /** Subscription id */
  subscriptionId: Stripe.Subscription['id'];
}

interface AccountState {
  /** Error when managing account */
  error?: Error | FirebaseError & { details: any };
  /** True when request to cancel subscription is inflight */
  inFlight: boolean;
  /** User's subscriptions */
  subscriptions: Stripe.Subscription[];

  nextAction: AccountActions;
}

declare module '@pwrs/state/state' {
  export interface State {
    account: AccountState;
    cancelDialog: CancelDialogState;
  }
}

const stripeGetSubscriptions =
  safeCallable<void, Stripe.Subscription[]>('stripeGetSubscriptions');

const stripeCancelSubscription =
  safeCallable<CancelSubscriptionParams, Stripe.Subscription>('stripeCancelSubscription');

function isInitialized(_, __, { initialized }): boolean {
  return initialized && initialized.auth;
}

export async function getSubscriptions(): Promise<GetSubscriptionsResult> {
  const { data, error } = await stripeGetSubscriptions();
  const subscriptions = data || [];
  await set('subscriptions', subscriptions);
  return { subscriptions, error };
}

export async function cancelSubscription(params: CancelSubscriptionParams): Promise<void> {
  const { subscriptionId } = params;
  updateState({ account: { inFlight: true }, cancelDialog: { inFlight: true } });
  const { data: response, error: cancelError } = await stripeCancelSubscription({ subscriptionId });
  const { subscriptions, error: subscriptionsError } = await getSubscriptions();
  const account = { error: subscriptionsError, inFlight: false, subscriptions };
  const cancelDialog = { error: cancelError, inFlight: false, open: !!cancelError, response };
  updateState({ account, cancelDialog });
}

async function initialize(): Promise<void> {
  const initialized = { account: true };
  const state = getState();
  const stored = await get('subscriptions') as Stripe.Subscription[]|undefined;
  // const isLoggedIn = !!(state.auth?.user);
  const isLoggedIn = !!(state.auth && state.auth.user);
  const subscriptions = !isLoggedIn ? [] : stored && stored.length ? stored : null;
  if (subscriptions)
    updateState({ account: { subscriptions }, initialized });
  else
    updateState({ account: { nextAction: 'getSubscriptions' }, initialized });
}

async function runActions(state: AccountState): Promise<void> {
  switch (state.nextAction) {
    case 'init':
      await initialize();
      break;
    case 'getSubscriptions': {
      updateState({ account: { inFlight: true } });
      const { subscriptions, error } = await getSubscriptions();
      updateState({ account: { inFlight: false, subscriptions, error } });
      break;
    }
  }
  updateState({ auth: { nextAction: null } });
}

export const accountStateInitializer: StateInitializer['account'] = {
  effects: [
    when(receivedProp('nextAction'), runActions),
    when(not(isInitialized), initialize),
  ],
  state: {
    error: null,
    inFlight: false,
    nextAction: null,
    subscriptions: [],
  },
};

export const cancelDialogStateInitializer: StateInitializer['cancelDialog'] = {
  state: {
    error: null,
    inFlight: false,
    open: false,
    planName: null,
    response: null,
    subscriptionId: null,
  },
};
